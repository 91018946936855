import request from './request'

const api = {
	// 获取列表
	lists: '/gh/ArticleCate/getPageList',
	// 添加
	add: '/gh/ArticleCate/create',
	// 删除
	dele: '/gh/ArticleCate/delete',
	// 编辑
	edit: '/gh/ArticleCate/update',
	// 详情
	info: '/gh/ArticleCate/read',
}

export {
	request,
	api
}
